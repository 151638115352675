<template>
  <nh-drawer
    v-model="drawerVisible"
    destroy-on-close
    size="704px"
    title="预约单"
  >
    <PlTable
      v-loading="loading.getTableData"
      :data="tableData"
      :columns="tableColumn"
    >
      <template #bookingDate="{row}">
        <div>
          <p>{{ moment.format(row.bookingDate, YMD) }}</p>
          <p>{{ row.bookingTime }}</p>
        </div>
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.reserveStatus === CHECK_PENDING"
          type="text"
          @click="headleToReview(row, emit)"
        >
          审核
        </nh-button>
      </template>
    </PlTable>
  </nh-drawer>
</template>

<script setup>
import { defineExpose, defineEmits } from 'vue';
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import {
  CHECK_PENDING,
} from '../../constant';
import tableColumn from './fileds';
import {
  drawerVisible,
  tableData,
  loading,
} from './data';
import getTableData from './uses/getTableData';
import headleToReview from './uses/openDrawer';

const emit = defineEmits(['onReview']);

const init = (row) => {
  drawerVisible.value = true;
  getTableData(row);
};

defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.alert {
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-left: 16px;
  line-height: 48px;
  background-color: #f9f1ec;
  border: 1px solid #fbbb98;
  border-radius: 4px;
  font-size: 14px;
  color: #fe5e08;
}

ul.flag-boxs {
  display: flex;
  font-size: 14px;

  li {
    list-style: none;
    display: flex;
    line-height: 32px;
    margin-right: 16px;

    span {
      display: inline-block;
      margin-top: 6px;
      margin-right: 8px;
      width: 40px;
      height: 20px;
    }
  }
}

.appoint-time-format {
  height: 48px;
  width: 100%;
}
</style>
<style lang="scss">
.el-table th.appoint-time-format-col {
  padding: 0;

  & > div.cell {
    padding: 0 !important;
    height: 48px;
  }
}
</style>
