import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import { TEMPERATURE_LAYER } from '@/constant/temperatureLayer';
import { formatKeyValueObject } from '@/utils/base';
import { isBoolean } from 'lodash';
import {
  infoData,
  loading,
  tableData,
  attachments,
} from '../data';
import { receiptReserveDetail } from '../../../api';

const temperature = formatKeyValueObject(TEMPERATURE_LAYER, {
  value: 'layer',
  label: 'name',
});

export default async (receiptReserveOrderId) => {
  loading.getReviewData = true;
  infoData.value = {};
  tableData.value = [];
  attachments.value = [];
  try {
    const resp = await receiptReserveDetail({ receiptReserveOrderId });
    infoData.value = {
      ...(resp.reserveOrder || {}),
      bookingTimeTxt: `${moment.format(resp.reserveOrder.bookingDate, YMD)}  ${resp.reserveOrder.bookingTime}`,
    };
    tableData.value = (resp.goodsDetails || []).map((item) => ({
      ...item,
      temperatureLayer: temperature[item.temperatureLayer],
      shelfLife: moment.format(item.shelfLife),
      crossDocking: (() => {
        if (isBoolean(item.crossDocking)) {
          return item.crossDocking ? '是' : '否';
        }
        return '';
      })(),
      batchAttr: (() => item.batchAttr.reduce(
        (prev, curr) => prev + curr.attrName,
        '',
      ))(),
      bookingNum: (() => {
        if (item.defaultUnit !== item.smallUnit && item.conversionRate && item.bookingNum) {
          return (item.bookingNum * 1000) / (item.conversionRate * 1000);
        }
        return item.bookingNum;
      })(),
    }));
    attachments.value = (resp.attachments || []).map((item) => ({
      ...item,
      createTime: moment.format(item.updateTime || item.createTime),
    }));
  } finally {
    loading.getReviewData = false;
  }
};
