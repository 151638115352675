import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:分页查询入库单记录表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19221
 */
export function receiptReservePage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/page', data, {
    params,
  });
}

/**
 * @description:入库预约单详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19223
 */
export function receiptReserveDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/detail', data);
}

/**
 * @description:入库预约单保存和更新
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19215
 */
export function receiptReserveSave(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/save', data);
}
/**
 * @description:仓库收货时间段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19255
 */
export function warehouseReceiptTimes(data) {
  return wmsPlusHttp.post('/warehouse_management_system/warehouse/receipt_times', data);
}

/**
 * @description:入库预约单审核
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19243
 */
export function reserveOrderAudit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/audit', data);
}
/**
 * @description:关联入库单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19273
 */
export function bindReceiptOrder(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/bind_receipt_order', data);
}
/**
 * @description:入库预约单到货登记
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19245
 */
export function reserveOrderCheckIn(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/check_in', data);
}
/**
 * @description:入库预约单计划视图
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19291
 */
export function reserveOrderPlanView(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/plan_view', data);
}/**
 * @description:入库预约单计划视图对应的预约单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19293
 */
export function planViewOrders(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_reserve_order/plan_view/orders', data);
}
