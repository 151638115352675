<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    size="1111px"
    title="入库预约计划"
  >
    <div class="alert">
      (该视图按日期显示时间段内预约到货的预约单数)
    </div>
    <nh-dialog-form label-width="108px" label-suffix=":">
      <nh-dialog-form-item
        label="收货月台数"
        :span="24"
      >
        <span>{{ options.storeAreaDocks.length || '' }}</span>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="预约到货日期" :span="8">
        <el-date-picker
          v-model="search.bookingDate"
          type="daterange"
          format="YYYY/MM/DD"
          :clearable="false"
          :value-format="YMD"
          @change="getTableData"
        />
      </nh-dialog-form-item>
      <el-col :span="16">
        <ul class="flag-boxs">
          <li v-for="item in flags" :key="item.label">
            <span :style="item.style" />{{ item.label }}
          </li>
        </ul>
      </el-col>
    </nh-dialog-form>
    <el-table
      ref="table"
      v-loading="loading.getTableData"
      class="apponit-view-table"
      :data="tableData"
      border
    >
      <el-table-column
        v-for="item in options.timeRanges"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :class-name="item.prop === DATE_COL ? 'appoint-time-format-col' : ''"
      >
        <template v-if="item.prop === DATE_COL" #header>
          <canvas
            ref="appointTimeFormat"
            class="appoint-time-format"
          />
        </template>
        <template #default="{ row }">
          <div v-if="item.prop === DATE_COL">
            <span>{{ moment(row.bookingDate).format(YMD) }}</span>
          </div>
          <div
            v-else
            class="box-cursor"
            :style="setbgColor(
              (row[item.prop]?.orderCount || 0) / (options.storeAreaDocks.length || 1)
            )"
            @click="row[item.prop] ? handleReservation(
              {...row[item.prop] ,bookingDate: row.bookingDate}, reservationDialog
            ): ''"
          >
            <p class="tac">
              {{ row[item.prop]?.orderCount }}
            </p>
            <span
              v-if="row[item.prop]?.preAuditCount"
              class="box-pre"
            >·待审：{{ row[item.prop]?.preAuditCount }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </nh-drawer>
  <Reservation ref="reservationDialog" @onReview="(data) => handleToReview(data, emit)" />
</template>
<script>
import {
  ref, defineExpose, defineEmits, nextTick,
} from 'vue';
import moment from 'moment';
import { YMD } from '@/constant/timeFormat';
import flags from './config';
import DATE_COL from './constant';
import {
  drawerVisible,
  search,
  options, tableData,
  loading,
} from './data';
import handleClose from './uses/handleClose';
import getTableData from './uses/getTableData';
import { getStoreAreaDocks, getTimeRanges } from './uses/getOptions';
import dragTableHeader from './uses/setCanvas';
import { handleReservation, handleToReview } from './uses/openDrawer';
import setbgColor from './uses/setStyleBgc';
import Reservation from '../Reservation/Reservation.vue';

export default {
  name: 'InWarehouseAppReview',
};
</script>

<script setup>
// ref
const reservationDialog = ref(null);
const appointTimeFormat = ref(null);
const emit = defineEmits(['onReview']);
const init = () => {
  drawerVisible.value = true;
  search.bookingDate = [moment().format(YMD), moment().add(7, 'days').format(YMD)];
  // 月台
  getStoreAreaDocks();
  getTimeRanges();
  getTableData();
  nextTick(() => {
    dragTableHeader(appointTimeFormat);
  });
};

defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.alert {
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-left: 16px;
  line-height: 48px;
  background-color: #f9f1ec;
  border: 1px solid #fbbb98;
  border-radius: 4px;
  font-size: 14px;
  color: #fe5e08;
}

ul.flag-boxs {
  display: flex;
  font-size: 14px;

  li {
    list-style: none;
    display: flex;
    line-height: 32px;

    &:not(:last-of-type) {
      margin-right: 16px;
    }

    span {
      display: inline-block;
      margin-top: 6px;
      margin-right: 8px;
      width: 40px;
      height: 20px;
    }
  }
}

.appoint-time-format {
  height: 48px;
  width: 100%;
}

.box-cursor {
  cursor: pointer;
  position: relative;

  .tac {
    text-align: center;
    line-height: 60px;
  }

  .box-pre {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
  }
}

</style>
<style lang="scss">
.apponit-view-table {
  th.appoint-time-format-col {
    padding: 0;

    & > div.cell {
      padding: 0 !important;
      height: 48px;
    }
  }

  tbody {
    td.el-table__cell,
    .el-table__cell:first-child {
      padding: 0;
      height: 60px;

      .cell {
        padding: 0;
      }
    }
  }
}

</style>
