const ruleDockId = [
  { required: true, message: '请选择', trigger: 'blur' },
];
const ruleHandling = [
  { required: true, message: '请选择', trigger: 'blur' },
];
const ruleOutsourceHandling = [
  { required: true, message: '请选择', trigger: 'blur' },
];

export { ruleDockId, ruleHandling, ruleOutsourceHandling };
