import { ref, reactive } from 'vue';

const drawerVisible = ref(false);
const tableData = ref([]);

const loading = reactive({
  getTableData: false,
});
export {
  drawerVisible,
  tableData,
  loading,
};
