<template>
  <nh-drawer
    v-model="drawerVisible"
    title="到货登记"
    size="1111px"
    :before-close="handleClose"
  >
    <div v-loading="loading.getReviewData" class="register-drawer-form">
      <nh-dialog-form
        ref="ruleFormRef"
        :model="infoData"
        label-width="120px"
        label-suffix="："
      >
        <nh-dialog-form-item
          label="车牌号"
          :span="6"
        >
          <el-input
            v-model="infoData.plateNo"
            disabled
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="司机"
          :span="6"
          label-width="130px"
        >
          <span v-text="infoData.driverName" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="司机电话"
          :span="6"
        >
          <span v-text="infoData.driverTel" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="车型"
          :span="6"
        >
          <span v-text="infoData.truckType" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="分配月台"
          prop="dockId"
          :span="6"
          :rules="ruleDockId"
        >
          <el-select
            v-model="infoData.dockId"
            filterable
            reserve-keyword
            placeholder="搜索匹配"
            :loading="loading.remoteMethod"
          >
            <el-option
              v-for="item in options.storeAreaDocks"
              :key="item.id"
              :label="item.dockCode"
              :value="item.id"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label-width="0"
          :span="18"
        >
          <nh-button
            type="text"
            @click="headleLoadingPort(useLoadPortDialog)"
          >
            查看月台使用情况
          </nh-button>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="是否需要装卸"
          :span="6"
          prop="handling"
          :rules="ruleHandling"
        >
          <el-select
            v-model="infoData.handling"
          >
            <el-option
              v-for="item in selectBoolean"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          v-if="infoData.handling"
          label="是否外包装卸"
          :span="6"
          prop="outsourceHandling"
          :rules="ruleOutsourceHandling"
        >
          <el-select
            v-model="infoData.outsourceHandling"
          >
            <el-option
              v-for="item in selectBoolean"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
      </nh-dialog-form>
      <nh-drawer-container title="订单基本信息">
        <nh-dialog-form label-width="120px">
          <nh-dialog-form-item
            v-for="item in orderInfo"
            :key="item.prop"
            :label="item.label"
            :span="item.span"
          >
            <template #default>
              <span>{{ infoData[item.prop] }}</span>
            </template>
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="附件"
            prop="files"
            :span="24"
          >
            <p>
              <nh-button
                type="text"
                @click="handleAllDownload(attachments)"
              >
                一键下载
              </nh-button>
            </p>
            <ul class="accessory">
              <li v-for="(item, index) in attachments" :key="index">
                <div>
                  {{ item.fileName }}
                </div>
                <div>
                  {{ item.createTime }}
                </div>
                <div>
                  <nh-button
                    type="text"
                    @click="headleDownload(item)"
                  >
                    下载
                  </nh-button>
                </div>
              </li>
            </ul>
          </nh-dialog-form-item>
        </nh-dialog-form>
      </nh-drawer-container>
      <tabs-table :table-data="tableData" />
    </div>
    <template #footer>
      <div class="footer">
        <div>
          <nh-button
            type="primary"
            @click="handleResolve(emit, ruleFormRef)"
          >
            登记
          </nh-button>
        </div>
      </div>
    </template>
  </nh-drawer>
  <UseLoadingPort
    ref="useLoadPortDialog"
    :table-header="options.storeAreaDocks"
    :date="infoData.bookingDate"
  />
</template>
<script>
import {
  ref,
  defineExpose,
  defineEmits,
} from 'vue';
import selectBoolean from './constant';
import orderInfo from './fileds';

import { ruleDockId, ruleHandling, ruleOutsourceHandling } from './rules';
import {
  drawerVisible,
  infoData,
  attachments, tableData,
  loading,
  options,
} from './data';
import getInfoData from './uses/getReviewData';
import headleLoadingPort from './uses/openDrawer';
import { handleClose, handleResolve } from './uses/submit';
import {
  headleDownload,
  handleAllDownload,
} from '../Review/uses/downLoad';
import loadWarehousePlatformPage from './uses/options';

import TabsTable from '../TabsTable/index.vue';
import UseLoadingPort from '../UseLoadingPort/index.vue';

export default {
  name: 'InWarehouseAppRegister',
};
</script>

<script setup>
const emit = defineEmits(['onSuccess']);
const ruleFormRef = ref(null);
const useLoadPortDialog = ref(null);

const init = ({ id }) => {
  drawerVisible.value = true;
  loadWarehousePlatformPage();
  getInfoData(id);
};

defineExpose({
  init,
});
</script>
<style lang="scss" scoped>

ul.accessory {
  li {
    list-style: none;
    display: flex;

    div {
      &:first-of-type {
        width: 260px;
        padding-right: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:nth-of-type(2) {
        width: 190px;
      }
    }
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

</style>
<style lang="scss">
.register-drawer-form {
  .el-input {
    width: 100%;
  }
}
</style>
