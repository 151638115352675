import { ref, reactive } from 'vue';

const options = reactive({
  timeRanges: [],
});

const searchQuery = ref({});
const pagination = reactive({
  page: 1,
  size: 10,
  total: 0,
});
const tableData = ref([]);
const loading = reactive({
  getTableData: false,
  timeRanges: false,
});

export {
  options,
  pagination,
  searchQuery,
  tableData,
  loading,
};
