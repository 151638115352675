// Canvas 绘制斜线表头
const dragTableHeader = (appointTimeFormat) => {
  if (appointTimeFormat) {
    const dom = appointTimeFormat.value;
    const ctx = dom.getContext('2d');
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(220, 223, 230, 1)';
    ctx.moveTo(0, 0);
    ctx.lineTo(500, 120);
    ctx.moveTo(0, 0);
    ctx.lineTo(550, 550);
    ctx.stroke();
    // 绘制字体
    ctx.font = '30px "微软雅黑"';
    ctx.fillStyle = 'rgba(96, 98, 102, 1)';
    ctx.textBaseline = 'top';
    ctx.fillText('日期', 20, 100);
    ctx.fillText('预约单数', 150, 90);
    ctx.fillText('时间段', 200, 8);
  }
};
export default dragTableHeader;
