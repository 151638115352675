const orderInfo = [{
  label: '预约单号',
  prop: 'receiptReserveOrderNo',
  span: 8,
},
{
  label: '货主名称',
  prop: 'shipperName',
  span: 8,
},
{
  label: '供应商',
  prop: 'supplierName',
  span: 8,
},
{
  label: '司机',
  prop: 'driverName',
  span: 8,
},
{
  label: '车牌',
  prop: 'plateNo',
  span: 8,
},
{
  label: '联系电话',
  prop: 'driverTel',
  span: 8,
},
{
  label: '车型',
  prop: 'truckType',
  span: 8,
}];

export default orderInfo;
