import { tableData, loading } from '../data';
import { planViewOrders } from '../../../api';

const getTableData = async ({ bookingDate, bookingTime }) => {
  loading.getTableData = true;
  tableData.value = [];
  try {
    tableData.value = await planViewOrders({ bookingDate, bookingTime });
  } finally {
    loading.getTableData = false;
  }
};

export default getTableData;
