import { ElMessage } from 'element-plus';
import { reserveOrderAudit } from '../../../api';
import { AUDIT_PASS, REJECTED } from '../../../constant';
import {
  drawerVisible,
  reviewData,
  loading,
} from '../data';

const onSubmit = async (status) => {
  await reserveOrderAudit({
    receiptReserveOrderId: reviewData.value.id,
    status,
    updateTime: reviewData.value.updateTime,
    remark: reviewData.value.auditRemark,
  });
};

const handleResolve = async (emit) => {
  loading.handleResolve = true;
  try {
    await onSubmit(AUDIT_PASS);
    drawerVisible.value = false;
    emit('onSuccess');
  } finally {
    loading.handleResolve = false;
  }
};
const handleReject = async (emit) => {
  if (!reviewData.value.auditRemark?.trim()) {
    ElMessage({
      showClose: true,
      message: '审核备注: 驳回时不能为空',
      type: 'error',
    });
    return;
  }
  loading.handleReject = true;
  try {
    await onSubmit(REJECTED);
    emit('onSuccess');
    drawerVisible.value = false;
  } finally {
    loading.handleReject = false;
  }
};
export {
  handleResolve,
  handleReject,
};
