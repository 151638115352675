import { drawerVisible } from '../data';
// 查看预约情况
const headleToReview = (row, emit) => {
  emit('onReview', {
    row,
    config: { isEdit: true },
  });
  drawerVisible.value = false;
};

export default headleToReview;
