<!--
 * @Author: your name
 * @Date: 2022-03-07 11:30:58
 * @LastEditTime: 2022-03-11 21:06:26
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-tabs
      v-model="activeName"
      class="appoint-review-tabs"
    >
      <el-tab-pane
        :label="tabs.label"
        :name="tabs.prop"
      />
    </el-tabs>
    <PlTable
      :data="props.tableData"
      :columns="tableColumn"
    />
  </div>
</template>
<script>
import {
  ref, defineProps,
} from 'vue';
import {
  tabs,
  tableColumn,
} from './fileds';

export default {
  name: 'InWarehouseTabsTable',
};
</script>

<script setup>
const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
});

const activeName = ref(tabs.prop);
</script>
<style lang="scss">
.appoint-review-tabs {
  .el-tabs__active-bar {
    padding: 0;
  }
}
</style>
