const selectBoolean = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];

export default selectBoolean;
