import { reserveOrderPlanView } from '../../../api';
import {
  tableData, loading, search,
} from '../data';
import DATE_COL from '../constant';

const getTableData = async () => {
  loading.getTableData = true;
  const [bookingDateStart, bookingDateEnd] = search.bookingDate;
  try {
    const { dateViews = [] } = await reserveOrderPlanView({
      bookingDateStart,
      bookingDateEnd,
    });
    tableData.value = dateViews.map((item) => item.timeViews.reduce((prev, curr) => ({
      ...prev,
      [curr.bookingTime]: {
        ...curr,
      },
    }), { [DATE_COL]: item.bookingDate }));
  } finally {
    loading.getTableData = false;
  }
};

export default getTableData;
