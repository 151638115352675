import { warehousePlatformPage } from '../../../../inbound-order/api';
import { warehouseReceiptTimes } from '../../../api';
import { options, loading } from '../data';
import DATE_COL from '../constant';

const getStoreAreaDocks = async () => {
  const resp = await warehousePlatformPage({ page: 1, size: 1000 }, { status: 'ENABLED' });
  // 预约提货 月台布局只展示收货月台
  const storeAreaDocks = resp.records.filter((item) => item.storeArea && item.status !== 'DISABLED');
  options.storeAreaDocks = storeAreaDocks;
};

const getTimeRanges = async () => {
  loading.timeRanges = true;
  options.timeRanges = [{
    label: '日期',
    prop: DATE_COL,
    width: 100,
  }];
  try {
    options.timeRanges = [
      ...options.timeRanges,
      ...(await warehouseReceiptTimes({}) || []).map((item) => ({
        label: `${item.startTime}-${item.endTime}`,
        prop: `${item.startTime}-${item.endTime}`,
        width: 150,
      }))];
  } finally {
    loading.timeRanges = false;
  }
};
export { getStoreAreaDocks, getTimeRanges };
