import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import {
  drawerVisible, tableColumn, tableData, loading,
} from '../data';
import { dockSubscriberList } from '../../../../inbound-order/api';
import { DOCK_TYPE_ENUM_VALUE, RECEIPT_STSTUS_ENUM_VALUE } from '../../../../inbound-order/constant';

const filtTableData = (sourceData) => {
  const data = [];
  const lists = [];

  //  只展示待验收 和已登记的数据
  sourceData.forEach((sourceItem) => {
    sourceItem.dockSubscribers.forEach((item) => {
      if (item.receiptOrderStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT
        || item.receiptOrderStatus === RECEIPT_STSTUS_ENUM_VALUE.ACCEPTING) {
        lists.push(item);
      }
    });
  });
  lists.forEach((item) => {
    let flag = false;
    for (let i = 0, l = data.length; i < l; i += 1) {
      if (!(item.dockId in data[i])) {
        data[i][item.dockId] = {
          ...item,
          time: item.checkInDate ? moment(item.checkInDate).format(YMDHMS) : '',
        };
        flag = true;
        break;
      }
    }
    if (!flag) {
      data.push({
        [item.dockId]: {
          ...item,
          time: item.checkInDate ? moment(item.checkInDate).format(YMDHMS) : '',
        },
      });
    }
  });
  return data;
};

const getTableCol = async (tableHeader) => {
  tableColumn.value = tableHeader.map((item) => ({
    label: item.dockCode,
    prop: item.id,
    width: 140,
  }));
};
const getTableData = async () => {
  drawerVisible.value = true;
  loading.getTableData = true;
  try {
    const result = await dockSubscriberList({}, {
      dockType: DOCK_TYPE_ENUM_VALUE.RECEIPT,
      checkInDate: moment().valueOf(),
    }) || [];
    tableData.value = filtTableData(result);
  } finally {
    loading.getTableData = false;
  }
};

export { getTableCol, getTableData };
