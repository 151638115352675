import { drawerVisible, data } from '../data';

export default () => {
  drawerVisible.value = false;
  data.search = {
    receiptStatus: 'PRE_CHECK_IN', // 待登记
  };
  data.pagination = {
    page: 1,
    size: 10,
    total: 0,
  };
  data.tableData = [];
};
