import {
  APPOINT_TYPE_TEXT,
} from '../../constant';

const tableColumn = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '预约到货时间',
  prop: 'bookingDate',
  width: 160,
}, {
  label: '预约单号',
  prop: 'receiptReserveOrderNo',
  width: 160,
}, {
  label: '预约状态',
  prop: 'reserveStatus',
  width: 80,
  formatter: ({ reserveStatus }) => APPOINT_TYPE_TEXT[reserveStatus],
}, {
  label: '货主名称',
  prop: 'shipperName',
  width: 80,
}, {
  label: '预约品类数',
  prop: 'goodsKindCount',
  width: 120,
},
{
  label: '预约量(默认单位)',
  prop: 'bookingTotalNum',
  width: 160,
},
{
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 80,
}];

export default tableColumn;
