<template>
  <nh-drawer
    v-model="drawerVisible"
    append-to-body
    title="使用情况"
    size="1111px"
  >
    <nh-drawer-container title="收货月台使用情况">
      <template #title>
        <p class="container-title">
          收货月台使用情况
          <span
            v-for="item in flags"
            :key="item.value"
            :style="item.style"
          >{{ item.label }}</span>
        </p>
      </template>
      <div v-loading="loading.getTableData">
        <PlTable
          :data="tableData"
          :columns="tableColumn"
          class="loading-port-table"
          border
        >
          <template
            v-for="item in tableColumn"
            :key="item.prop"
            #[item.prop]="{ row }"
          >
            <div class="port-table-cell" :style="flagesEnum[row[item.prop]?.receiptOrderStatus]">
              <h4>{{ row[item.prop]?.shipperName }}</h4>
              <div><p>{{ row[item.prop]?.plateNo }}</p></div>
              <p
                v-if="row[item.prop]?.receiptOrderStatus !== RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT"
                class="time"
              >
                {{ row[item.prop]?.time }}
              </p>
            </div>
          </template>
        </PlTable>
      </div>
    </nh-drawer-container>
  </nh-drawer>
</template>
<script>
import { defineExpose, defineProps } from 'vue';
import {
  drawerVisible,
  tableColumn, tableData,
  loading,
} from './data';
import { flags, flagesEnum } from './constant';
import { getTableData, getTableCol } from './uses/table';
import { RECEIPT_STSTUS_ENUM_VALUE } from '../../../inbound-order/constant';

export default {
  name: 'InWarehouseAppUseLoadingPort',
};
</script>

<script setup>
const props = defineProps({
  tableHeader: {
    type: Array,
    required: true,
    default: () => [],
  },
  date: {
    type: [String, Number],
    default: '',
  },
});
const init = () => {
  getTableCol(props.tableHeader);
  getTableData();
};

defineExpose({
  init,
});
</script>
<style lang="scss" scope>
.container-title {
  span {
    display: inline-block;
    margin-left: 8px;
    padding: 4px 16px;
    font-weight: 400;
  }
}

.loading-port-table {
  .port-table-cell {
    padding: 4px;
    height: 110px;
    position: relative;

    h4 {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    div {
      min-height: 40px;

      p {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .time {
      position: absolute;
      left: 0;
      bottom: 8px;
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss">
.loading-port-table {
  tbody {
    td.el-table__cell,
    .el-table__cell:first-child {
      padding: 0;

      .cell {
        padding: 0;
      }
    }
  }
}
</style>
