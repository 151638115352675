import { ref, reactive } from 'vue';

const drawerVisible = ref(false);
const infoData = ref({});
const tableData = ref([]);
const attachments = ref([]);
const loading = reactive({
  getReviewData: false,
});
const options = reactive({
  storeAreaDocks: [],
});

export {
  drawerVisible,
  infoData,
  tableData,
  attachments,
  loading,
  options,
};
