import { drawerVisible } from '../data';
// 查看预约单
const handleReservation = (row, reservationDialog) => {
  if (!reservationDialog && !row) return;
  reservationDialog.init(row);
};

// 预约单审核
const handleToReview = (data, emit) => {
  emit('onReview', data);
  drawerVisible.value = false;
};
export { handleReservation, handleToReview };
