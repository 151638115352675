import { searchQuery } from '../data';
import getTableData from './table';

const handleSearch = () => {
  const [startCreateTime, endCreateTime] = searchQuery.value.createTime || [];
  searchQuery.value = {
    ...searchQuery.value,
    startCreateTime,
    endCreateTime,
  };
  getTableData();
};
const handleReset = () => {
  searchQuery.value.bookingTime = undefined;
  handleSearch();
};
export { handleSearch, handleReset };
