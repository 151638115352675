const flags = [{
  label: '时段内预约单数较少',
  style: {
    backgroundColor: '#07c160',
  },
  isRange(num) {
    return num < 0.9;
  },
}, {
  label: '时段内预约单数超过月台数90%',
  style: {
    backgroundColor: '#ff9d07',
  },
  isRange(num) {
    return num >= 0.9 && num <= 1;
  },
}, {
  label: '时段内预约单数已超过月台数',
  style: {
    backgroundColor: '#f56c6c',
  },
  isRange(num) {
    return num > 1;
  },
}];
export default flags;
