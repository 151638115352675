import { bindReceiptOrder } from '../../../api';
import { loading, receiptReserveOrderId } from '../data';
import handleClose from './closeDrawer';

export default async (ids, emit) => {
  loading.bindReceiptOrder = true;
  try {
    await bindReceiptOrder({
      receiptReserveOrderId: receiptReserveOrderId.value,
      receiptOrderIds: ids,
    });
    emit('onSuccess');
    handleClose();
  } finally {
    loading.bindReceiptOrder = false;
  }
};
