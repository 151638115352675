const tabs = {
  label: '预约货品明细',
  prop: 'goodsDetail',
};
const tableColumn = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  width: 180,
}, {
  label: '商品名称',
  prop: 'goodsName',
  width: 160,
}, {
  label: '规格',
  prop: 'spec',
  width: 80,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  width: 80,
}, {
  label: '保质期(天)',
  prop: 'shelfLife',
  width: 170,
},
{
  label: '是否越库',
  prop: 'crossDocking',
  width: 100,
},
{
  label: '批属性(预约)',
  prop: 'batchAttr',
  width: 120,
},
{
  label: '换算关系',
  prop: 'conversionRate',
  width: 120,
},
{
  label: '默认单位',
  prop: 'defaultUnit',
  width: 100,
},
{
  label: '预约数量(默认单位)',
  prop: 'bookingNum',
  width: 150,
},
{
  label: '货主订单号',
  prop: 'customerOrderNo',
  width: 150,
},
{
  label: '入库单号',
  prop: 'receiptOrderNo',
  width: 150,
},
{
  label: '外部单号',
  prop: 'externalOrderNo',
  width: 150,
}];

export {
  tabs,
  tableColumn,
};
