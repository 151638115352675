<template>
  <nh-drawer
    v-model="drawerVisible"
    :title="isEdit?'预约单审核': '详情'"
    size="1111px"
  >
    <div v-loading="loading.getReviewData">
      <nh-drawer-container title="订单基本信息">
        <nh-dialog-form label-width="120px" label-suffix="：">
          <nh-dialog-form-item
            v-for="item in orderInfo"
            :key="item.prop"
            :label="item.label"
            :span="item.span"
          >
            <template #default>
              <span class="word-wrap-text">{{ reviewData[item.prop] }} <el-tag
                v-if="item.prop === 'receiptReserveOrderNo' && !isEdit"
                type="warning"
                effect="dark"
              >
                {{ APPOINT_TYPE_TEXT[reviewData.reserveStatus] }}
              </el-tag></span>
            </template>
          </nh-dialog-form-item>
          <nh-dialog-form-item label="预约到货时间" :span="24">
            <p>
              {{ `${moment.format(reviewData.bookingDate, YMD)}  ${reviewData.bookingTime}` }}
              <nh-button
                v-if="isEdit"
                type="text"
                @click="headleAppointView(reviewData, appointViewDialog)"
              >
                查看预约情况
              </nh-button>
            </p>
          </nh-dialog-form-item>
          <nh-dialog-form-item
            v-if="!isEdit"
            label="审核备注"
            :span="24"
          >
            <p>
              {{ reviewData.auditRemark }}
            </p>
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="附件"
            prop="files"
            :span="24"
          >
            <p>
              <nh-button
                :loading="loading.download"
                type="text"
                @click="handleAllDownload(attachments)"
              >
                一键下载
              </nh-button>
            </p>
            <ul class="accessory">
              <li v-for="(item, index) in attachments" :key="index">
                <div>
                  {{ item.fileName }}
                </div>
                <div>
                  {{ item.createTime }}
                </div>
                <div>
                  <nh-button
                    type="text"
                    @click="headleDownload(item)"
                  >
                    下载
                  </nh-button>
                </div>
              </li>
            </ul>
          </nh-dialog-form-item>
        </nh-dialog-form>
        <TabsTable :table-data="tableData" />
        <nh-dialog-form
          v-if="isEdit"
          class="review_data-form"
          label-width="120px"
          label-suffix="："
        >
          <nh-dialog-form-item label="审核备注" :span="24">
            <el-input
              v-model="reviewData.auditRemark"
              :rows="2"
              type="textarea"
              :maxlength="100"
              show-word-limit
              placeholder="驳回时必填，最多可输入100个文字"
            />
          </nh-dialog-form-item>
        </nh-dialog-form>
      </nh-drawer-container>
    </div>
    <template v-if="isEdit" #footer>
      <div class="footer">
        <div>
          <nh-button
            type="primary"
            :loading="loading.handleResolve"
            @click="handleResolve(emit)"
          >
            审核通过
          </nh-button>
          <nh-button
            :loading="loading.handleReject"
            @click="handleReject(emit)"
          >
            审核驳回
          </nh-button>
        </div>
      </div>
    </template>
    <AppointView ref="appointViewDialog" @onReview="handleReivew" />
  </nh-drawer>
</template>
<script>
import { ref, defineExpose, defineEmits } from 'vue';
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import { APPOINT_TYPE_TEXT } from '../../constant';
import { handleResolve, handleReject } from './uses/submit';
import orderInfo from './fileds';

import {
  isEdit,
  drawerVisible, reviewData,
  tableData, attachments,
  loading,
} from './data';
import getReviewData from './uses/getReviewData';
import headleAppointView from './uses/openDrawer';
import {
  headleDownload,
  handleAllDownload,
} from './uses/downLoad';

import TabsTable from '../TabsTable/index.vue';
import AppointView from '../AppointView/index.vue';

export default {
  name: 'InWarehouseAppReview',
};
</script>

<script setup>
const emit = defineEmits(['onSuccess']);
const appointViewDialog = ref(null);

const init = ({ id }, config) => {
  isEdit.value = config.isEdit;
  drawerVisible.value = true;
  getReviewData(id);
};

const handleReivew = ({ row, config }) => {
  isEdit.value = config.isEdit;
  drawerVisible.value = true;
  getReviewData(row.id);
};
defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.word-wrap-text {
  word-wrap: break-word;
}

ul.accessory {
  li {
    list-style: none;
    display: flex;

    div {
      &:first-of-type {
        width: 260px;
        padding-right: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:nth-of-type(2) {
        width: 190px;
      }
    }
  }
}

.review_data-form {
  margin-top: 16px;
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
