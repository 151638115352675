import { reserveOrderCheckIn } from '../../../api';
import {
  drawerVisible,
  infoData,
  tableData,
  attachments,
  options,
  loading,
} from '../data';

const handleClose = () => {
  infoData.value = {};
  tableData.value = [];
  attachments.value = [];
  options.storeAreaDocks = [];
  drawerVisible.value = false;
};
const handleResolve = async (emit, ruleFormRef) => {
  loading.handleResolve = true;
  if (!ruleFormRef) return;
  try {
    await ruleFormRef.validate();
    await reserveOrderCheckIn({
      ...infoData.value,
      receiptReserveOrderId: infoData.value.id,
    });
    handleClose();
    emit('onSuccess');
  } finally {
    loading.handleResolve = false;
  }
};

export {
  handleResolve,
  handleClose,
};
