import {
  data, loading,
} from '../data';
import {
  inboundOrderPage,
} from '../../../../inbound-order/api';

const getTableData = async () => {
  loading.getTableData = true;
  try {
    const {
      records, total,
    } = await inboundOrderPage(data.pagination, data.search);
    data.tableData = records;
    data.pagination.total = total;
  } finally {
    loading.getTableData = false;
  }
};

const handleSearch = () => {
  getTableData();
};
const handleReset = () => {
  handleSearch();
};

const handleUpdatePage = ({ page, size }) => {
  data.pagination.page = page;
  data.pagination.size = size;
  getTableData();
};
export {
  getTableData,
  handleSearch,
  handleReset,
  handleUpdatePage,
};
