// 打开预约审核
const headleReview = (row, config, reviewDialog) => {
  reviewDialog.init(row, config);
};
// 打开到货登记
const headleRegister = (row, registerDialog) => {
  registerDialog.init(row);
};
// 打开入库单列表
const headleInboundOrder = (row, inboundOrderDialog) => {
  inboundOrderDialog.init(row);
};

export {
  headleReview,
  headleRegister,
  headleInboundOrder,
};
