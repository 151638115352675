import { formatKeyValueObject } from '@/utils/base';
import { RECEIPT_STSTUS_ENUM_VALUE } from '../../../inbound-order/constant';

const flags = [{
  label: '已登记',
  value: RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT,
  style: {
    backgroundColor: '#FDE2E2',
  },
}, {
  label: '验收中',
  value: RECEIPT_STSTUS_ENUM_VALUE.ACCEPTING,
  style: {
    backgroundColor: '#F9E7CC',
  },
}];
const flagesEnum = formatKeyValueObject(flags, { label: 'style' });
export { flags, flagesEnum };
