<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.getReviewData"
    :before-close="handleClose"
    destroy-on-close
    title="关联入库单"
    size="1111px"
  >
    <PlForm
      v-model="search"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleReset"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="search.shipperId"
        />
      </template>
    </PlForm>

    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="handleUpdatePage"
      >
        <template #receiptOrderNo="{row}">
          <nh-link
            :to="{}"
            :text="row.receiptOrderNo"
          />
          <div v-if="row.isImport" class="import-box">
            进口
          </div>
        </template>
        <template #action="{row}">
          <nh-button
            :loading="loading.bindReceiptOrder"
            type="text"
            @click="handleBindReceiptOrder([row.id], emit)"
          >
            关联
          </nh-button>
        </template>
      </PlTable>
    </pl-block>
    <template #footer>
      <div class="footer">
        <div>
          <nh-button
            @click="handleClose"
          >
            关闭
          </nh-button>
        </div>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import { toRefs, defineExpose, defineEmits } from 'vue';
import mixin from '../../../inbound-order/mixin';
import { TABLECOLUMN, serchFields } from './fileds';
import {
  drawerVisible,
  receiptReserveOrderId,
  data, loading,
} from './data';
import {
  getTableData,
  handleSearch,
  handleReset,
  handleUpdatePage,
} from './uses/table';
import handleBindReceiptOrder from './uses/bindReceiptOrder';
import handleClose from './uses/closeDrawer';

export default {
  name: 'InWarehouseAppOrder',
  mixins: [mixin],
};
</script>
<script setup>
const emit = defineEmits(['onSuccess']);

const init = ({ id, shipperId }) => {
  drawerVisible.value = true;
  receiptReserveOrderId.value = id;
  data.search.shipperId = shipperId;
  getTableData();
};

const {
  search,
  pagination,
  tableData,
} = toRefs(data);

defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
