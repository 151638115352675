import { ref, reactive } from 'vue';

const isEdit = ref(false);
const drawerVisible = ref(false);
const reviewData = ref({});
const tableData = ref([]);
const attachments = ref([]);
const loading = reactive({
  getReviewData: false,
  download: false,
});

export {
  isEdit,
  drawerVisible,
  reviewData,
  tableData,
  attachments,
  loading,
};
