import flags from '../config';

const setbgColor = ((num) => {
  let style = {};
  if (Number(num)) {
    style = flags.find((item) => item.isRange(num))?.style || {};
  }
  return style;
});
export default setbgColor;
