import { ref, reactive } from 'vue';

const drawerVisible = ref(false);
const options = reactive({
  timeRanges: [],
  storeAreaDocks: [],
});
const search = reactive({
  bookingDate: [],
});
const tableData = ref([]);
const loading = reactive({
  timeRanges: false,
  getTableData: false,
});

export {
  drawerVisible,
  options,
  search,
  tableData,
  loading,
};
