import { downloadFile } from '../../../../inbound-order/api';
import { loading } from '../data';

// // 保存到本地并自动点击
// const saveAs = (data, name) => {
//   const urlObject = window.URL || window.webkitURL || window;
//   const exportblob = new Blob([data]);
//   const link = document.createElement('a');
//   link.href = urlObject.createObjectURL(exportblob);
//   link.download = name;
//   link.click();
// };
// // 下载含有url的文件
// const downloadUrlFile = (url, fileName) => {
//   const url2 = url.replace(/\\/g, '/');
//   const xhr = new XMLHttpRequest();
//   xhr.open('GET', url2, true);
//   xhr.responseType = 'blob';
//   xhr.onload = () => {
//     if (xhr.status === 200 && xhr.readyState === 4) {
//       // 获取文件blob数据并保存
//       saveAs(xhr.response, fileName);
//     }
//   };
//   xhr.send();
// };
const headleDownload = async (row) => {
  loading.download = true;
  const { filePath, fileName } = row;
  const [name, extension] = fileName.split('.');
  try {
    await downloadFile({ path: filePath }, {
      fileName: name,
      extension,
    });
  } finally {
    loading.download = false;
  }
};
const handleAllDownload = (list) => {
  list.forEach((item) => {
    headleDownload(item);
  });
};
export {
  headleDownload,
  handleAllDownload,
};
