<template>
  <div class="in-warehouse-appoint-list">
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        :form-props="{labelWidth: '120px'}"
        @submit="handleSearch"
        @reset="handleReset"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchQuery.shipperId"
          />
        </template>
        <template #bookingDate>
          <div class="date-range">
            <el-date-picker
              v-model="searchQuery.bookingDate"
              type="date"
              :value-format="YMD"
            />
            <span>-</span>
            <el-select
              v-model="searchQuery.bookingTime"
              value-key="value"
              clearable
            >
              <el-option
                v-for="item in options.timeRanges"
                :key="item.value"
                :label="item.value"
                :value="item"
              />
            </el-select>
          </div>
        </template>
      </PlForm>
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="handleUpdatePage"
      >
        <template #[APPOINT_NO]="{row}">
          <nh-link
            :to="{}"
            :text="row[APPOINT_NO]"
            @click="headleReview(row, {isEdit: false}, reviewDialog)"
          />
        </template>
        <template #[APPOINT_TYPE]="{row}">
          <p v-text="APPOINT_TYPE_TEXT[row[APPOINT_TYPE]]" />
          <el-tooltip
            v-if="row[APPOINT_TYPE] === REJECTED"
            popper-class="appoint_list_box-item"
            effect="dark"
            placement="right"
          >
            <template #content>
              <div class="content">
                审核备注：
                <p>{{ row.auditRemark }}</p>
              </div>
            </template>
            <nh-button type="text">
              查看原因
            </nh-button>
          </el-tooltip>
        </template>
        <template #bookingTime="{row}">
          <div>
            <p>{{ moment.format(row.bookingDate, YMD) }}</p>
            <p>{{ row.bookingTime }}</p>
          </div>
        </template>
        <template #action="{row}">
          <nh-button
            v-if="row[APPOINT_TYPE] === CHECK_PENDING"
            type="text"
            @click="headleReview(row, {isEdit: true}, reviewDialog)"
          >
            审核
          </nh-button>
          <!-- 仅审核通过且预约单未关联入库单时 -->
          <nh-button
            v-if="row[APPOINT_TYPE] === AUDIT_PASS && !row.includeReceiptOrder"
            type="text"
            @click="headleInboundOrder(row, inboundOrderDialog)"
          >
            关联入库单
          </nh-button>
          <!-- 仅审核通过且已关联了入库单时显示 -->
          <nh-button
            v-if="row[APPOINT_TYPE] === AUDIT_PASS && row.includeReceiptOrder"
            type="text"
            @click="headleRegister(row, registerDialog)"
          >
            到货登记
          </nh-button>
        </template>
      </PlTable>
    </pl-block>
    <Review ref="reviewDialog" @onSuccess="handleGetTable" />
    <Register ref="registerDialog" @onSuccess="handleGetTable" />
    <InboundOrder ref="inboundOrderDialog" @onSuccess="handleGetTable" />
  </div>
</template>
<script>
import { ref } from 'vue';
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import {
  options,
  pagination, searchQuery, tableData, loading,
} from './data';
import {
  APPOINT_NO, AUDIT_PASS, REJECTED,
  APPOINT_TYPE, APPOINT_TYPE_TEXT,
  CHECK_PENDING,
} from './constant';
import { serchFields, TABLECOLUMN } from './fileds';
import getTimeRanges from './uses/getOptions';
import handleGetTable from './uses/table';
import handleUpdatePage from './uses/pageChange';
import { handleSearch, handleReset } from './uses/queryTable';
import {
  headleReview,
  headleRegister,
  headleInboundOrder,
} from './uses/openDrawer';
import Review from './Components/Review/index.vue';
import Register from './Components/Register/index.vue';
import InboundOrder from './Components/InboundOrder/index.vue';

export default {
  name: 'InWarehouseAppointmentList',
};
</script>

<script setup>
// ref
const reviewDialog = ref(null);
const registerDialog = ref(null);
const inboundOrderDialog = ref(null);
const init = () => {
  getTimeRanges();
  handleGetTable();
};
init();

</script>
<style lang="scss" scoped>
.in-warehouse-appoint-list {
  .date-range {
    display: flex;
  }
}
</style>
<style lang="scss">
.appoint_list_box-item {
  .content {
    min-width: 160px;
    padding-bottom: 16px;

    p {
      margin-top: 4px;
      text-indent: 1em;
    }
  }
}
</style>
