import { ref, reactive } from 'vue';

const drawerVisible = ref(false);
const tableColumn = ref([]);
const tableData = ref([]);
const loading = reactive({
  getTableData: false,
});

export {
  drawerVisible,
  tableColumn, tableData,
  loading,
};
