const orderInfo = [{
  label: '预约单号',
  prop: 'receiptReserveOrderNo',
  span: 6,
},
{
  label: '货主名称',
  prop: 'shipperName',
  span: 6,
},
{
  label: '供应商',
  prop: 'supplierName',
  span: 12,
},
{
  label: '预约到货时间',
  prop: 'bookingTimeTxt',
  span: 12,
},
];

export default orderInfo;
