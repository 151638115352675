import {
  pagination, searchQuery,
  tableData, loading,
} from '../data';
import { receiptReservePage } from '../api';

export default async () => {
  loading.getTableData = true;
  try {
    const {
      records, total,
    } = await receiptReservePage(pagination, searchQuery.value);
    tableData.value = records;
    pagination.total = total;
  } finally {
    loading.getTableData = false;
  }
};
