import { TABLECOLUMN as tableCol, serchFields as search } from '../../../inbound-order/fileds';

const serchFields = search.reduce((prev, curr) => {
  if (curr.prop === 'receiptStatus') {
    return [...prev, {
      ...curr,
      componentAttrs: {
        disabled: true,
      },
    }];
  }
  return [...prev, curr];
}, []);
const TABLECOLUMN = tableCol.map((item) => ({
  ...item,
  width: item.prop === 'action' ? 80 : item.width,
}));

export {
  TABLECOLUMN, serchFields,
};
