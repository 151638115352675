import { formatKeyValueArray } from '@/utils/base';

// 预约单号
const APPOINT_NO = 'receiptReserveOrderNo';
// 预约状态
const APPOINT_TYPE = 'reserveStatus';
const CHECK_PENDING = 'PRE_AUDIT';
const AUDIT_PASS = 'AUDIT_PASS';
const REJECTED = 'REJECTED';
const APPOINT_TYPE_TEXT = {
  [CHECK_PENDING]: '待审核',
  [AUDIT_PASS]: '审核通过',
  [REJECTED]: '已驳回',
  ARRIVED: '已到货',
  CANCELLED: '已取消',
  INVALID: '已失效',
};
const APPOINT_TYPE_OPTION = formatKeyValueArray(APPOINT_TYPE_TEXT);

export {
  APPOINT_NO,
  APPOINT_TYPE,
  CHECK_PENDING,
  AUDIT_PASS,
  REJECTED,
  APPOINT_TYPE_TEXT,
  APPOINT_TYPE_OPTION,
};
