import { FormType } from '@/constant/form';
import moment from '@/utils/moment';
import { YMD, YMDHMS } from '@/constant/timeFormat';
import Table from '@/constant/table';
import { StoreBillTypeText } from '@/constant/task';
import { formatKeyValueArray } from '@/utils/base';
import {
  APPOINT_NO,
  APPOINT_TYPE, APPOINT_TYPE_OPTION, APPOINT_TYPE_TEXT,
} from './constant';

// 入库单类型
const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);

const { TIME_COLUMN_WIDTH } = Table;

const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  }, {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '预约单号',
    prop: APPOINT_NO,
    minWidth: 160,
  }, {
    label: '预约到货时间',
    prop: 'bookingTime',
    minWidth: 160,
    formatter: ({ bookingTime }) => moment.format(bookingTime),
  }, {
    label: '预约状态',
    prop: APPOINT_TYPE,
    minWidth: 120,
    formatter: (row) => APPOINT_TYPE_TEXT[row[APPOINT_TYPE]],
  }, {
    label: '货主名称',
    prop: 'shipperName',
    minWidth: 140,
  }, {
    label: '供应商',
    prop: 'supplierName',
    minWidth: 140,
  }, {
    label: '预约量(默认单位)',
    prop: 'bookingTotalNum',
    minWidth: 140,
  }, {
    label: '预约总重量(kg)',
    prop: 'bookingTotalWeight',
    minWidth: 140,
  }, {
    label: '预约总方量(m³)',
    prop: 'bookingTotalVolume',
    minWidth: 140,
  }, {
    label: '预约发起时间',
    prop: 'createTime',
    minWidth: TIME_COLUMN_WIDTH,
    formatter: ({ createTime }) => `${moment.format(createTime, YMDHMS)}`,
  }, {
    label: '预约发起人',
    prop: 'supplierContactName',
    minWidth: TIME_COLUMN_WIDTH,
  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 80,
  }];

const serchFields = [
  {
    label: '预约单号',
    prop: APPOINT_NO,
    component: FormType.INPUT,
  }, {
    label: '入库单号',
    prop: 'receiptOrderNo',
    component: FormType.INPUT,
  }, {
    label: '货主订单号',
    prop: 'customerOrderNo',
    component: FormType.INPUT,
  }, {
    label: '外部单号',
    prop: 'externalOrderNo',
    component: FormType.INPUT,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '预约状态',
    prop: APPOINT_TYPE,
    component: FormType.SELECT,
    options: APPOINT_TYPE_OPTION,
  }, {
    label: '入库单类型',
    prop: 'reserveType',
    component: FormType.SELECT,
    options: RECEIPT_TYPE,
  }, {
    label: '车牌号',
    prop: 'plateNo',
    component: FormType.INPUT,
  }, {
    label: '供应商',
    prop: 'supplierName',
    component: FormType.INPUT,
  }, {
    label: '预约到货时间',
    prop: 'bookingDate',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'date',
      valueFormat: YMD,
    },
  }, {
    label: '预约发起时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
      valueFormat: YMDHMS,
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 1, 1, 23, 59, 59),
      ],
    },
  },
];

export {
  TABLECOLUMN,
  serchFields,
};
