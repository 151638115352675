import {
  drawerVisible,
  tableData,
} from '../data';

const handleClose = async () => {
  tableData.value = [];
  drawerVisible.value = false;
};

export default handleClose;
