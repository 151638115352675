import { ref, reactive } from 'vue';

const receiptReserveOrderId = ref();
const drawerVisible = ref(false);
const data = reactive({
  search: {
    receiptStatus: 'PRE_CHECK_IN', // 待登记
  },
  pagination: {
    page: 1,
    size: 10,
    total: 0,
  },
  tableData: [],
});
const loading = reactive({
  getTableData: false,
  bindReceiptOrder: false,
});
export {
  receiptReserveOrderId,
  drawerVisible,
  loading,
  data,
};
