import { warehouseReceiptTimes } from '../api';
import { options, loading } from '../data';

const getTimeRanges = async () => {
  loading.timeRanges = true;
  try {
    options.timeRanges = (await warehouseReceiptTimes({}) || []).map((item) => ({
      ...item,
      startBookingTime: item.startTime,
      endBookingTime: item.endTime,
      value: `${item.startTime} - ${item.endTime}`,
    }));
  } finally {
    loading.timeRanges = false;
  }
};
export default getTimeRanges;
